@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/breakpoints.scss';

.login {
    &-wrapper {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include lg {
            background-color: #f9f9f9;
        }
        &-title {
                margin: 0;
                color: $secondary;
                font-size: 50px;
                text-align: center;
                line-height: 50px;
                margin-bottom: 5%;
                text-decoration: underline;
                
                @include lg {
                    margin-bottom: 3%;
                    text-decoration: none;
                }
        }
    }

    &-form {
        border-radius: 10px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include lg {
            box-shadow: $box1;
            background-color: $bwhite;
            width: 400px;
            height: 500px;
        }
        h2 {
            margin: 0;
            color: $primary;
            font-size: 40px;
        }

        &-fieldset {
            display: flex;
            flex-direction: column;
            margin: 10% 0 5% 0;
            width: 70%;

            input {
                margin-bottom: 10%;
                border: 1px solid $primary;
                background-color: #f9f9f9;
                padding: 10px 15px;
                border-radius: 5px;
                width: calc(100% - 30px);
            }
        }

        .submit-btn {
            padding: 3% 15%;
            background-color: $secondary;
            display: flex;
            align-items: center;
            border-radius: 50px;
            cursor: pointer;

            p {
                margin: 0;
                margin-right: 5px;
                color: $bwhite;
            }
        }
    }
}