@import '../../assets/styles/variables.scss';
@import '../../assets/styles/breakpoints.scss';

.navbar {
    &-title {
        color: $bwhite;
    }
    &-wrapper {
        z-index: 1;
        position: fixed;
        top: 0;
        width: calc(100% - 4%);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        // min-height: 50px;
        padding: 0 2%;
        align-items: center;
        background-color: black;
        height: 100px;
        @include lg {
            justify-content: space-between;
            height: 80px;
            flex-direction: row;
        }
        .add-new-budget {
            padding: 10px 3%;
            background-color: $primary;
            display: flex;
            align-items: center;
            border-radius: 50px;
            svg {
                color: $bwhite;
            }
            p {
                margin: 0;
                margin-right: 5px;
                color: $bwhite;
            }
        }
        .home-btn {
            border-radius: 50px;
            border: 2px solid $primary;
            background-color: $primary;
            color: white;
            padding: 5px 20px;
            cursor: pointer;
            width: 110px;
            display: flex;
            justify-content: center;
            &:hover {
                border-color: white;
            }
        }
        .logout-btn {
            border-radius: 50px;
            border: 2px solid $secondary;
            color: white;
            background-color: $secondary;
            padding: 5px 20px;
            cursor: pointer;
            margin-left: 15px;
            width: 75px;
            display: flex;
            justify-content: center;
            &:hover {
                border-color: white;
            }
        }
    }
    &-element {
        cursor: pointer;
    }

    &-btns {
        display: flex;
    }
}