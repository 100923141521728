@import './assets/styles/variables.scss';
@import './assets/styles/breakpoints.scss';

.btn {
    border-radius: 50px;
    padding: 10px 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    p {
        margin: 0;
    }
}

h1, h2, h3, p {
    margin: 0;
}

.content-wrapper {
    position: relative;
}

.page-wrapper {
    position: relative;
    margin-top: 100px;
    background-color: $background;
    min-height: calc(100vh - 120px);
    padding: 4%;
    @include lg {
        margin-top: 80px;
        padding: 4% 8%;
    }
}