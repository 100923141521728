@import '../../assets/styles/variables.scss';

.modal {
    &-overlay {
        display: none;
        /* Hidden by default */
        position: fixed;
        /* Stay in place */
        z-index: 1;
        /* Sit on top */
        left: 0;
        top: 0;
        width: 100%;
        /* Full width */
        height: 100%;
        /* Full height */
        background-color: rgb(0, 0, 0);
        /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4);
        /* Black w/ opacity */
        align-items: center;
        justify-content: center;
    }

    &-wrapper {
        position: fixed;
        height: 300px;
        width: 400px;
        background-color: $bwhite;
        border-radius: 10px;
    }

    &-open {
        display: flex;
    }
    &-close {
        padding-right: 3%;
        padding-top: 3%;
        width: calc(100% - 3%);
        text-align: right;
        svg {
            cursor: pointer;
            font-size: 30px;
        }
    }
}