@import '../../assets/styles/variables.scss';

.dropdown{
    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // align-items: center;
        position: relative;
        margin: 3px 0;
        width: 49%;
        flex-wrap: wrap;
        cursor: pointer;
    }

    &-label {
        min-width: 250px;
    }

    &-input {
        font-size: 18px;
        padding: 10px;
        border-radius: 5px;
        outline: none;
        border: 2px solid $border;
        background-color: #f6f6f6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-text {
            width: unset!important;
        }
    }
    &-options {
        width: calc(100% - 4px);
        z-index: 1;
        display: none;
        flex-direction: column;
        position: absolute;
        background-color: white;
        border-radius: 5px;
        max-height: 250px;
        overflow: scroll;
        // padding: 10px;
        top: 64px;
        border: 2px solid $border;
        &-open {
            display: flex;
        }
        .selected-option {
            background-color: $background;
        }
    }
    &-option {
        padding: 10px;
        width: calc(100% - 20px)!important;
        // border-radius: 5px;
        &:hover {
            color: white;
            background-color: $primary;
        }
    }
}