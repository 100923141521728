@import '../../assets/styles/variables.scss';

.input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: center;
    margin: 3px 0;
    width: 49%;
    flex-wrap: wrap;
    .input-label {
        min-width: 250px;
    }
    input {
        font-size: 18px;
        padding: 10px;
        border-radius: 5px;
        outline: none;
        border: 2px solid $border;
    }
}