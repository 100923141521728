@import '../../assets/styles/variables.scss';
@import '../../assets/styles/breakpoints.scss';

.budget {
    &-wrapper {
        .upcoming {
            &-expense-item {
                color: #dd2c22;
            }
            &-income-item {
                color: #30f552;
            }
        }
        .date-selected {
            width: 100%;
            text-align: center;
            font-size: .9em;
            font-weight: 500;
            margin-bottom: 15px;
            margin-top: -10px;
            &-wrapper {
                    display: flex;
                    flex-direction: column;
                    padding: 0 5%;
                    .date-data {
                        width: 100%;
                        margin: 1% 0;
                        
                        &-wrapper {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }

                        &-spacer {
                            border-bottom: 3px dotted black;
                            width: 100%;
                            flex: 1;
                            margin: 0 5px;
                        }

                        &-income {
                            display: flex;
                            justify-content: space-between;
                            align-items: baseline;
                            width: 100%;
                            font-size: 1.2em;
                            h2 {
                                font-size: 1.2em;
                                color: #30f552;
                            }
                        }
            
                        &-expense {
                            display: flex;
                            justify-content: space-between;
                            align-items:baseline;
                            width: 100%;
                            font-size: 1.2em;
                            h2 {
                                font-size: 1.2em;
                                color: #dd2c22;
                            }
                        }
                    }
                }
        }

    }
    &-graph {
        // border-radius: 10px;
        // box-shadow: $box1;
        
        // background-color: $bwhite;
        width: 95%;
        margin: 2% 0 0 2%;
        height: 320px;
        display: flex;
        justify-content: center;
        @include lg {
            height: 400px;
            margin-right: 2%;
            width: 45%;
        }
        &-wrapper {
            padding: 2% 0;
            margin-top: 2%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            border-radius: 10px;
            background-color: $bwhite;
            box-shadow: $box1;
            @include lg {
                flex-direction: row;
            }
        }
    }
    &-legend {
        // padding-left: 5%;
        // margin-top: 2%;
        width: 92%;
        padding-left: 8%;
        padding-bottom: 2%;
        @include lg {
            padding-left: 0;
            width: 38%;
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        &-item {
            display: flex;
            margin: 1% 0;
            align-items: center;
        }
        &-color {
            height: 20px;
            width: 20px;
            border-radius: 50px;
            margin-right: 15px;
        }
        &-name {
            font-size: 20px;
            width: 50%;
        }
        &-value {
            font-size: 20px;
            font-weight: 500;
        }
    }
    &-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        &-btns {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            // margin-right: 6%;
            .container {
                align-items: center;
                margin-right: 10%;
            }
            .toggle-switch {
                top: 0;
            }
        }
        h2 {
            font-size: 2.5em;
            text-decoration:underline;
        }
    }
    &-edit-btn {
        color: $primary;
        border: 2px solid $primary;
        background-color: $bwhite;
        min-width: 125px;
        &:hover {
            background-color: $primary;
            color: $bwhite;
        }
    }
    &-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        @include sm {
        }
        @include lg {
            flex-wrap: wrap;
            flex-direction: row;
        }
    }
    &-upcoming {
        padding: 2% 5%;
        box-shadow: $box1;
        border-radius: 10px;
        background-color: $bwhite;
        width: 90%;
        margin-bottom: 4%;
        @include lg {
            margin-bottom: 0;
            width: 39%;
        }
        // margin-top: 2%;
        display: flex;
        flex-direction: column;
        margin-right: 2%;
        h1 {
            font-size: 2em;
        }
        .no-upcoming {
            margin-top: 4%;
            font-size: 1.2em;
        }
        &-date {
        
                &-day {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: black;
                    color: white;
                    // border: 2px solid black;
                    border-radius: 5px;
                    height: 60px;
                    width: 60px;
        
                    &-top {
                        font-size: 1.5em;
                        font-weight: bold;
                    }
        
                    &-bottom {
                        font-size: 1.2em;
                    }
                }
        
                &-wrapper {
                    display: flex;
                    justify-content: space-between;
                    margin: 2% 0;
                }
                &-expense {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
                &-expenses {
                    margin-left: 5%;
                    font-size: 1.2em;
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                }
            }
    }
    &-elements {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 4%;
        @include lg {
            margin-top: 2%;
            flex-direction: row;
        }
        h2 {
            font-size: 1.8em;
        }
    }
    &-cards {
        width: 100%;
        margin-top: 4%;
        flex-wrap: wrap;
        @include lg {
            flex-wrap: nowrap;
            margin-top: 2%;
        }
        display: flex;
        // flex-wrap: wrap;
        justify-content: space-between;
        // margin-right: 5%;
    }
    &-expenses-list {
        width: 90%;
        padding: 5%;
        margin-bottom: 4%;
        box-shadow: $box1;
        border-radius: 10px;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        @include lg {
            padding: 2% 3%;
            margin-bottom: 0;
            margin-right: 2%;
            width: 48%;
        }
        // flex-direction: column;
        h2 {
            width: 50%;
            margin-bottom: 2%;
        }
        span {
            width: 50%;
        }
    }
    &-income-list {
        width: 90%;
        padding: 5%;
        box-shadow: $box1;
        border-radius: 10px;
        background-color: white;
        display: flex;
        flex-direction: column;
        @include lg {
            padding: 2% 3%;
            width: 48%;
        }
        h2 {
            width: 50%;
            margin-bottom: 2%;
        }
        span {
            width: 50%;
        }
    }
    &-list-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid black;
        width: 100%;
        max-height: 30px;
        // padding-bottom: 1%;
        padding: 1% 0;
        font-size: 1.3em;
        @include lg {
            width: 48%;
        }
        p {
            // margin-right: 2%;s
        }
    }
    &-card {
        box-shadow: $box1;
        border-radius: 10px;
        background-color: $bwhite;
        // border: 2px solid $secondary;
        width: 48%;
        margin-bottom: 4%;
        text-align: center;
        @include lg {
            width: 23.5%;
            margin-bottom: unset;
        }
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        flex-direction: column;
        &-green {
            color: #30f552;
        }
        &-red {
            color: #dd2c22;
        }
        &-blue {
            color: #0b7fab;
        }
        p {
            font-size: 1.2em;
            margin-top: -2%;
        }
        h2 {
            margin-top: 3%;
            font-size: 1.7em;
            font-weight: 800;
        }
    }
    &-calendar {
        box-shadow: $box1;
        border-radius: 10px;
        background-color: $bwhite;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: flex-start;
        width: 90%;
        @include lg {
            width: 39%;
        }
        // margin-top: 2%;
        // margin-bottom: 5%;
        padding: 2% 5%;
        p {
            font-size: 2em;
            font-weight: bold;
            // margin-bottom: 3%;
        }
    }
}