@import '../../assets/styles/variables.scss';

.calendar {
    &-wrapper {
            .highlight {
                color: $primary!important;
                font-weight: bold!important;
            }
        
            .selected-date {
                margin: 0 auto;
            }
        
            .times button {
                display: block;
                background-color: #6f48eb;
                color: black;
                margin-top: 5px;
            }
        
            /* react calendar */
        
            .react-calendar {
                border: 0;
                // padding: 4%;
                width: 100%;
                max-width: 100%;
                background-color: $bwhite;
                color: black;
                // border-radius: 10px;
                // box-shadow: $box1;
                font-family: Arial, Helvetica, sans-serif;
                // line-height: 1.125em;
            }
        
            .react-calendar__navigation button {
                color: black;
                min-width: 44px;
                background: none;
                font-size: 16px;
                margin-top: 8px;
            }
        
            .react-calendar--doubleView {
                width: 700px;
            }
        
            .react-calendar--doubleView .react-calendar__viewContainer {
                display: flex;
                margin: -0.5em;
            }
        
            .react-calendar--doubleView .react-calendar__viewContainer>* {
                width: 50%;
                margin: 0.5em;
            }
        
            .react-calendar,
            .react-calendar *,
            .react-calendar *:before,
            .react-calendar *:after {
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }
        
            .react-calendar button {
                margin: 0;
                // border: 0;
                outline: none;
            }
        
            .react-calendar button:enabled:hover {
                cursor: pointer;
            }
        
            .react-calendar__navigation {
                display: flex;
                height: 44px;
                margin-bottom: 1em;
            }
        
            .react-calendar__navigation button {
                min-width: 44px;
                /* background: none; */
            }
        
            .react-calendar__navigation button:enabled:hover,
            .react-calendar__navigation button:enabled:focus {
                background-color: #e6e6e6;
            }
        
            .react-calendar__navigation button[disabled] {
                background-color: #f0f0f0;
            }
        
            .react-calendar__month-view__weekdays {
                text-align: center;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 0.75em;
                padding-top: 10px;
            }
        
            .react-calendar__month-view__weekdays__weekday {
                padding: 0.5em;
            }
        
            .react-calendar__month-view__weekNumbers .react-calendar__tile {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.75em;
                font-weight: bold;
                padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
            }
        
            .react-calendar__month-view__days__day--weekend {
                /* color: #d10000; */
                color: black;
            }
        
            .react-calendar__month-view__days__day--neighboringMonth {
                color: black;
            }
        
            .react-calendar__year-view .react-calendar__tile,
            .react-calendar__decade-view .react-calendar__tile,
            .react-calendar__century-view .react-calendar__tile {
                padding: 2em 0.5em;
            }
        
            .react-calendar__tile {
                max-width: 100%;
                text-align: center;
                border: 2px solid white;
                // border-radius: 50%;
                background: none;
                height: 50px;
                width: 50px;
            }
        
            .react-calendar__tile:disabled {
                background-color: #f0f0f0;
            }

            .react-calendar__tile--active {
                color: black;
            }
        
            .react-calendar__tile:enabled:hover,
            .react-calendar__tile:enabled:focus {
                background-color: #e6e6e6;
                color: black;
            }
        
            .react-calendar__tile--now {
                /* background: #ffff76; */
                // background-color: $secondary;
                // border-radius: 50%;
                border: 2px solid $primary;
                // color: $bwhite;
            }
        
            .react-calendar__tile--now:enabled:hover,
            .react-calendar__tile--now:enabled:focus {
                background: $bwhite;
                border: 2px solid $secondary;
                color: black;
            }
        
            // .react-calendar__tile--hasActive {
            //     background: #76baff;
            // }
        
            .react-calendar__tile--hasActive:enabled:hover,
            .react-calendar__tile--hasActive:enabled:focus {
                background: #a9d4ff;
            }
        
            // .react-calendar__tile--active {
            //     background: $primary;
            //     color: white;
            // }
        
            // .react-calendar__tile--active:enabled:hover,
            // .react-calendar__tile--active:enabled:focus {
            //     background: #1087ff;
            // }
        
            .react-calendar--selectRange .react-calendar__tile--hover {
                background-color: #e6e6e6;
            }
    }
}