@import '../../assets/styles/variables.scss';
@import '../../assets/styles/breakpoints.scss';

.edit {
    &-wrapper {
        width: 96%;
        position: relative;
        margin: 0 2%;
        padding-bottom: 20%;
        @include lg {
            padding-bottom: 10%;
            width: calc(100% - 100px);
            margin: 0 50px;
        }
    }

    &-header {
        width: 100%;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $border;
        padding: 0 0 20px 0;

        h1 {
            margin: 0;
            font-size: 2.5em;
        }
    }

    &-form {
        display: flex;
        flex-direction: column;
        width: 100%;

        h2 {
            margin: 1% 0 -1% 0;
            font-size: 25px;
        }

        &-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .form-title {
            display: flex;
            flex-direction: column;
            width: 100%;
            @include lg {
                width: 49%;
            }

            p {
                font-weight: 500;
                // width: 40%;
            }


            .input-wrapper {
                width: 100%;
            }

            input {
                width: 100% !important;
            }
        }

        .form-favorite {
            width: 100%;
            display: flex;
            align-items: center;
            margin-top: 2%;
            @include lg {
                margin-top: unset;
                margin-left: 10%;
                width: 40%;
            }
            input {
                margin-left: 4%;
                height: 20px;
                width: 20px;
            }
        }

        .income-section {
            .section-header {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 2% 0 2% 2%;
                width: 98%;
                font-weight: bold;
                border-bottom: 1px solid $border;

                p {
                    font-size: 1.5em;
                    margin-right: 1%;
                }

                svg {
                    font-size: 1.5em;
                }
            }

            &-holder {
                max-height: 0;
                overflow: hidden;
                visibility: hidden;
                transition: visibility 1s, max-height 1s, overflow 1s;
                .input-wrapper, .dropdown-wrapper {
                    width: 88%;
                    @include lg {
                        width: 49%;
                    }
                }
                .trash-icon {
                    right: -15px;
                    @include lg {
                        right: 0;
                    }
                }
            }

            .show-section {
                overflow: visible;
                visibility: visible;
                max-height: 10000px;
            }
        }

        .expense-section {
            .section-header {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 2% 0 2% 2%;
                width: 98%;
                font-weight: bold;
                border-bottom: 1px solid $border;
                p {
                    font-size: 1.5em;
                    margin-right: 1%;
                }

                svg {
                    font-size: 1.5em;
                }
            }

            &-holder {
                max-height: 0;
                overflow: hidden;
                visibility: hidden;
                transition: visibility 1s, max-height 1s, overflow 1s;
                .input-wrapper,
                    .dropdown-wrapper {
                        width: 88%;
                
                        @include lg {
                            width: 49%;
                        }
                    }
                
                    .trash-icon {
                        right: -15px;
                
                        @include lg {
                            right: 0;
                        }
                    }
            }

            .show-section {
                overflow: visible;
                visibility: visible;
                max-height: 10000px;
            }
        }

        &-add-btn {
            cursor: pointer;
            padding: 10px 25px;
            text-align: center;
            width: 150px;
            background-color: $primary;
            border-radius: 50px;
            color: $bwhite;

            p {
                margin: 0;
            }

            &-wrapper {
                width: 100%;
                display: flex;
                justify-content: center;
                margin: 2% 0;
            }
        }

        &-fieldset {
            width: 100%;
            padding: 0 15%;

            &-top {
                display: flex;

                .container {
                    margin-top: .3%;
                }

                h2 {
                    margin-right: 5%;
                }
            }
        }
    }

    &-btn-holder {
        display: flex;
        align-items: center;
        position: fixed;
        justify-content: center;
        width: 100%;
        bottom: 0;
        height: 70px;
        background-color: white;
        right: 0;
        z-index: 2;
        
        @include lg {
            justify-content: flex-end;
            margin-left: -11.5%;
            padding-right: 2%;
        }
        // width: 22%;
        // justify-content: space-evenly;
        .cancel-btn {
            border: 2px solid $secondary;
            color: $secondary;
            margin-right: 15px;

            &:hover {
                background-color: $secondary;
                color: $bwhite;
            }
        }

        .save-btn {
            margin-right: 5%;
            color: $bwhite;
            background-color: $primary;
            border: 2px solid $primary;

            &:hover {
                background-color: $darkPrimary;
            }
        }
    }
}