@import '../../assets/styles/variables.scss';
@import '../../assets/styles/breakpoints.scss';

.budget-form {
    &-fieldset {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid $border;
        width: 100%;
        padding: 20px 0;
        @include lg {
            flex-direction: row;
            flex-wrap: wrap;
            width: 90%;
            padding: 20px 5%;
        }
        .trash-icon {
            cursor: pointer;
            font-size: 25px;
            color: #dd2c22;
            position: absolute;
            border-radius: 50%;
            right: 0;
            top: 40%;
            height: 30px;
            width: 30px;
            padding: 10px;
            svg {
                width: 100%;
            }
            &:hover {
                background-color: #dd2c22;
                color: white;
            }
        }

        .input-wrapper, .dropdown-wrapper {
            p {
                width: 40%;
                font-weight: 500;
            }
            input {
                // width: 50%;
            }
        }
        &-toggle {
            display: flex;
            margin: 3px 0;
            width: 49%;
            &-label {
                width: 40%;
                font-weight: 500;
            }
        }
        .annual-options {
            display: flex;
            justify-content: space-between;
            width: 49%;
            .dropdown-wrapper {
                width: 48%;
                flex-wrap: nowrap;
            }
        }
    }
}